import { useState } from 'react';
import { event } from 'components/GoogleTagManager';
import Link from 'next/link';
import RightCaret from 'public/image/right-caret.svg';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { useGetCatalog } from 'queries/useGetCatalog';
import isArray from 'lodash/isArray';
import { categoryUrlGeneratorWithUrlKey } from 'utils/category-url';
import { useRouter } from 'next/router';

const NavMenuLinks = () => {
  const [subMenuId, setSubMenuId] = useState(-1);
  const { data } = useGetCatalog();

  function isIncludedInMenu(array) {
    for (const item of array) {
      if (item.include_in_menu === true) {
        return true;
      }
    }
    return false;
  }

  const { push } = useRouter();
  // @ts-ignore
  const validCategories = isArray(data)
    ? data?.filter((item: any) => item.product_count !== 0 && item.include_in_menu)
    : [];

  return (
    <>
      {validCategories?.map((parent: any) => {
        return (
          <li
            key={parent.entity_id}
            className='cursor-pointer p-[4px] w-full hover:bg-gray-10 group'
            onClick={() => event('main_navigation', { tab_selected: parent.name })}
          >
            <>
              <Link href={categoryUrlGeneratorWithUrlKey(parent?.url_key)} prefetch={false} legacyBehavior>
                <a
                  className='flex items-center justify-between link-item'
                  onClick={(e) => {
                    e.preventDefault();
                    push(categoryUrlGeneratorWithUrlKey(parent?.url_key)).finally(() => {
                      window.location.reload();
                    });
                  }}
                >
                  <span className='text-gray-900'>{truncateText(parent?.name, 40)}</span>
                  {!!parent.children_data?.length && isIncludedInMenu(parent?.children_data) && (
                    <span className='rtl:rotate-180'>
                      <RightCaret />
                    </span>
                  )}
                </a>
              </Link>

              {parent.children_data?.length !== 0 &&
                parent.children_data &&
                isIncludedInMenu(parent?.children_data) && (
                  <div className='z-10 absolute w-56 bg-[#fff] divide-y divide-gray-100 translate-x-[204px] rtl:translate-x-[-204px] rtl:translate-y-[-36px] translate-y-[-35px] hidden group-hover:block clear-both'>
                    <ul className='py-1 text-sm clear-both divide-y divide-gray-200'>
                      <>
                        {parent.children_data
                          ?.filter((child: any) => child.product_count !== 0 && child.include_in_menu)
                          ?.map((item: any, id: any) => (
                            <li
                              className='px-4 py-2 group relative clear-both'
                              key={id}
                              onClick={() =>
                                event('main_navigation', {
                                  tab_selected: item.name,
                                })
                              }
                              onMouseEnter={() => setSubMenuId(item.entity_id)}
                              onMouseLeave={() => setSubMenuId(-1)}
                            >
                              <Link
                                className='bg-red'
                                href={categoryUrlGeneratorWithUrlKey(item?.url_key)}
                                prefetch={false}
                                legacyBehavior
                              >
                                <a
                                  className='flex items-center justify-between sub-link-item'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    push(categoryUrlGeneratorWithUrlKey(item?.url_key)).finally(() => {
                                      window.location.reload();
                                    });
                                  }}
                                >
                                  <span className='text-gray-900'>{truncateText(item?.name, 40)}</span>
                                  {!!item.children_data.length && isIncludedInMenu(item?.children_data) && (
                                    <span className='rtl:rotate-180'>
                                      <RightCaret />
                                    </span>
                                  )}
                                </a>
                              </Link>
                              {subMenuId === item.entity_id &&
                                item.children_data &&
                                item.children_data.length > 0 &&
                                isIncludedInMenu(item?.children_data) && (
                                  <div className='z-10 absolute w-56 bg-[#fff] translate-x-[208px] rtl:translate-x-[-208px] rtl:translate-y-[-36px] translate-y-[-36px]'>
                                    <ul className='py-1 text-sm divide-y divide-gray-200'>
                                      <>
                                        {item?.children_data
                                          ?.filter((child: any) => child.product_count !== 0 && child.include_in_menu)
                                          ?.map((child: any, id: any) => (
                                            <li
                                              key={id}
                                              className='px-4 py-2'
                                              onClick={() =>
                                                event('main_navigation', {
                                                  tab_selected: 'offers',
                                                })
                                              }
                                            >
                                              <Link
                                                className='bg-red'
                                                href={categoryUrlGeneratorWithUrlKey(child?.url_key)}
                                                prefetch={false}
                                                legacyBehavior
                                              >
                                                <a
                                                  className='flex items-center justify-between sub-link-item'
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    push(categoryUrlGeneratorWithUrlKey(child?.url_key)).finally(() => {
                                                      window.location.reload();
                                                    });
                                                  }}
                                                >
                                                  <span className='text-gray-900'>{truncateText(child?.name, 40)}</span>
                                                </a>
                                              </Link>
                                            </li>
                                          ))}
                                      </>
                                    </ul>
                                  </div>
                                )}
                            </li>
                          ))}
                      </>
                    </ul>
                  </div>
                )}
            </>
          </li>
        );
      })}
    </>
  );
};

export default NavMenuLinks;
